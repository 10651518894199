import { API_MAP, POST_API_MAP } from './constants';

export const getApiBase = () => process.env.REACT_APP_API_BASE;

async function redirectToLogin() {
    console.log('Redirecting to login');
    window.location.href = `/login?redirect=${window.location.href}`;
    return null;
};

export async function fetchData(apiName, attribute, callBack, args) {
    let url = `${getApiBase()}/${API_MAP[apiName]}`;

    if (args && args.username) {
        url += `/${args.username}`;
    }

    return fetch(url).then(
        response => {
            if (response.status === 401) {
                throw Error(response.status);
            } else if (response.status !== 200) {
                throw Error(response.status);
            }
            return response.json();
        }
    ).then(
        data => data[attribute]
    ).then(
        data => callBack(data)
    ).catch(
        error => {
            // statusCode is an Error object
            if (error.message === '401') {
                console.log("Redirecting to login");
                return redirectToLogin();
            }
            console.log(`Error fetching data: ${error.message}`)
            return [];
        }
    )
}

export async function postData(apiName, attribute, data) {
    let url = `${getApiBase()}/${POST_API_MAP[apiName]}`;

    return fetch(
        url,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    ).then(
        response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 401) {
                return redirectToLogin();
            }
            return response.json().then(json => { throw new Error(json.message)});
        }
    ).then(
        data => data[attribute]
    )
}

import React, { useEffect, useState } from 'react';
import { fetchData } from "../api";
import moment from 'moment';
import UserInfo from '../components/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setChatters } from '../reducers/chatters';
import { formatTime, getDuration } from '../util';
import Table from '../components/Table';


const Chatters = ({ channel }) => {
    const selectedChannel = useSelector(state => state.selectedChannel.selectedChannel);
    const chatters = useSelector(state => selectedChannel ? state.chatters.channels[selectedChannel.name] || {} : {});
    const dispatch = useDispatch();

    // Prevent multiple fetches of the same resource
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            // Already fetching, skip
            return;
        }

        if (!selectedChannel) {
            // No channel selected, skip
            return;
        }

        if (chatters.users) {
            // Already have data, skip
            return;
        }

        if (chatters.lastUpdated && moment().format('X') - chatters.lastUpdated < 10) {
            // Data is less than 10 seconds old, skip
            return;
        }

        // Set fetch status
        setIsLoading(true);
        const cb = chatters => {
            dispatch(setChatters({ chatters: chatters, channel: selectedChannel }));
            setIsLoading(false);
        };
        fetchData('chatters', 'chatters', cb, { username: selectedChannel.name });
    }, [selectedChannel, chatters, dispatch, isLoading]);

    if (!selectedChannel) {
        return null;
    }

    return (
        <section id="chatters">
            <Table columns={[
                { name: 'ID', className: 'id', format: row => row.id, sortKey: 'id', sortable: true },
                { name: 'DisplayName', className: 'short', format: row => (<UserInfo userName={row.name} />), sortKey: 'name', sortable: true },
                { name: 'Created', className: 'date', format: row => formatTime(row.created_at), sortKey: 'created_at', sortable: true },
                { name: 'First seen', className: 'date', format: row => formatTime(row.first_seen), sortKey: 'first_seen', sortable: true },
                { name: 'Last seen', className: 'date', format: row => formatTime(row.last_seen), sortKey: 'last_seen', sortable: true },
                { name: 'Flags', className: 'short', format: row => (<span>{row.is_broadcaster ? '@' : ''}{row.is_mod ? '%' : ''}{row.subscriber ? '+' : ''}{row.is_vip ? '~' : ''}</span>), sortKey: '', sortable: false },
                { name: 'Banned', className: 'bool', format: row => row.is_banned ? '💀' : '', sortKey: 'is_banned', sortable: true },
                { name: 'Lurk time', className: 'short', format: row => getDuration(row.lurk_time), sortKey: 'lurk_time', sortable: true },
                { name: 'Actions', className: 'actions', format: row => (<><button className="btn-edit" href="#">📝</button><button className="btn-delete" href="#">🗑</button></>), sortKey: '', sortable: false },
            ]} data={chatters.users || []} isLoading={isLoading} title={"Chatters"} />
        </section>
    );
};

export default Chatters;

import React from 'react';
import Popup from 'reactjs-popup';
import PopupBase from './PopupBase';

const ConfirmDelete = ({ title, target, action }) => (
    <Popup trigger={<button className="btn-delete" href="#">🗑</button>} modal nested>
        {
            close => (
                <PopupBase title={title} close={close}>
                    <div className="confirm-delete popup-body">
                        <p>Are you sure you want to delete <strong>{target}</strong>?</p>
                        <div className="buttons">
                            <button className="btn-delete" onClick={() => {
                                action().then(
                                    () => close()
                                )
                            }
                            }>Delete</button>
                            <button className="btn-cancel" onClick={() => {
                                close();
                            }
                            }>Cancel</button>
                        </div>
                    </div>
                </PopupBase>
            )
        }
    </Popup>
);

export default ConfirmDelete;

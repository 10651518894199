import React, { useEffect } from 'react';
import { fetchData } from '../api';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../reducers/users';

const UserInfo = ({ userName }) => {
    const user = useSelector(state => state.users.users[userName.toLowerCase()]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            const cb = userInfo => {
                return dispatch(setUser(userInfo));
            };
            fetchData('user', 'user', cb, { username: userName });
        }
    }, [user, userName, dispatch]);

    if (!user) {
        return null;
    }

    return (
        <>
            <span className="user-info">
                <img src={user.profile_image} alt="User profile" className="user-image" />
                <strong>{user.display_name}</strong>
                <div className="user-popup">
                    <img src={user.profile_image} alt="User profile" className="user-image" />
                    <strong>{user.display_name}</strong>
                    <div>Created: {user.created_at}</div>
                    <div className="user-description">{user.description}</div>
                    <div className="arrow"></div>
                </div>
            </span>
        </>
    )
}

export default UserInfo;

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const chattersSlice = createSlice({
    name: 'chatters',
    initialState: {
        channels: {}
    },
    reducers: {
        setChatters: (state, action) => (
            {
                ...state,
                channels: {
                    ...state.channels,
                    [action.payload.channel.name]: {
                        ...state.channels[action.payload.channel.name],
                        users: action.payload.chatters,
                        lastUpdated: moment().format('X')
                    }
                }
            }
        )
    }
});

export const { setChatters } = chattersSlice.actions;
export default chattersSlice.reducer;

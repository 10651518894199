import React from 'react';

const SortDirection = ({ column, sortedColumn, direction }) => {
    if (column === sortedColumn) {
        if (direction === 'asc') {
            return <span className="sort-direction">▲</span>
        } else {
            return <span className="sort-direction">▼</span>
        }
    }
};

const TableHead = ({ columns, sortColumn, setSortColumn }) => {
    const updateSortColumn = column => {
        if (!column.sortable) {
            console.log(`Column ${column.name} is not sortable`);
            return;
        }

        if (sortColumn.column === column.sortKey) {
            setSortColumn({
                column: column.sortKey,
                direction: sortColumn.direction === 'asc' ? 'desc' : 'asc'
            });
        } else {
            setSortColumn({
                column: column.sortKey,
                direction: sortColumn.direction
            });
        }
    };

    return (
        <thead>
            <tr>
                {columns.map((column) => (
                    <th key={column.name} className={`${column.className} ${column.sortable ? 'sortable' : ''}`} onClick={() => updateSortColumn(column)}><SortDirection column={column.sortKey} sortedColumn={sortColumn.column} direction={sortColumn.direction} />{column.name}</th>
                ))}
            </tr>
        </thead>
    )
};

export default TableHead;

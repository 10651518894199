import React from 'react';

const PopupBase = ({ title, close, children }) => {
    return (
        <div className="popup">
            <h2>{title}</h2>
            <button onClick={() => { close(); }} className="close-popup">&times;</button>
            <hr />
            {children}
        </div>
    )
};

export default PopupBase;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { fetchData } from '../api';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedChannel } from '../reducers/selectedChannel';
import { setAvailableChannels } from '../reducers/availableChannels';

const Channel = ({ channel, selectedChannel, path }) => {
    const dispatch = useDispatch();
    const isSelected = selectedChannel && selectedChannel.name === channel.name;
    return (
        <li className={classNames({ user: true, selected: isSelected })}>
            <Link to={`/${channel.name}/${path}`} onClick={() => dispatch(setSelectedChannel(channel))}>
                <img src={`${channel.profile_image}`} alt={channel.name} />
            </Link>
        </li>
    )
}

const ChannelSwitcher = ({ path }) => {
    const { availableChannels, queried } = useSelector(state => state.availableChannels);
    const dispatch = useDispatch();

    useEffect(() => {
        const setChannels = channels => {
            dispatch(setAvailableChannels(channels));
        };

        if (!queried) {
            fetchData('channels', 'channels', setChannels);
        }
    }, [dispatch, queried]);

    const { selectedChannel } = useSelector(state => state.selectedChannel);

    return (
        <ul id="channels">
            {availableChannels.map((channel, index) => (<Channel key={index} selectedChannel={selectedChannel} channel={channel} path={path} />))}
        </ul>
    );
};

export default ChannelSwitcher;

import React, { useEffect } from 'react';
import { fetchData, postData } from "../api";
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { setCommands } from '../reducers/commands';
import 'reactjs-popup/dist/index.css';
import AddCommand from '../components/AddCommand';
import EditCommand from '../components/EditCommand';
import ConfirmDelete from '../components/ConfirmDelete';
import Popup from '../components/Popup';
import AccessLevel from '../components/AccessLevel';
import Table from '../components/Table';

const TableRow = ({ command }) => {
    const selectedChannel = useSelector(state => state.selectedChannel.selectedChannel);
    const dispatch = useDispatch();

    return (
        <tr>
            <td className="id">{command.id}</td>
            <td className="short">{command.name}</td>
            <td className="long">{command.response}</td>
            <td className="bool">{command.respond_to_user ? '✅' : '❌'}</td>
            <td className="short"><AccessLevel permission={command.access_level} /></td>
            <td className="bool">{command.is_enabled ? '✅' : '❌'}</td>
            <td className="date">{moment(command.last_used).format("YYYY-MM-DD HH:mm:ss")}</td>
            <td className="short">{command.times_used}</td>
            <td className="actions">
                <Popup trigger={<button className="btn-edit">📝</button>} component={EditCommand} title="Edit command" args={{ command }} />
                <ConfirmDelete
                    title="Delete command"
                    target={command.name}
                    action={() => postData('deleteCommand', 'command', { command_id: command.id }).then(
                        () => {
                            const cb = commandList => dispatch(setCommands({ commandList: commandList, channel: selectedChannel }));
                            fetchData('commands', 'commands', cb, { username: selectedChannel.name });
                        }
                    )}
                    />
            </td>
        </tr>
    );
};

const Commands = () => {
    const selectedChannel = useSelector(state => state.selectedChannel.selectedChannel);
    const commands = useSelector(state => selectedChannel ? state.commands.channels[selectedChannel.name] || {} : {});
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedChannel && (!commands.commandList || (!commands.lastUpdated || moment().format('X') - commands.lastUpdated > 10))) {
            const cb = commandList => dispatch(setCommands({ commandList: commandList, channel: selectedChannel }));
            fetchData('commands', 'commands', cb, { username: selectedChannel.name });
        }
    }, [selectedChannel, commands, dispatch]);

    const existingCommands = Object.values(commands.commandList || {});

    return (
        <section id="commands">
            <Table
                title="Channel Commands"
                columns={[
                    { name: 'ID', sortKey: 'id', className: 'id', format: row => row.id, sortable: true },
                    { name: 'Command', sortKey: 'name', className: 'short', format: row => row.name, sortable: true },
                    { name: 'Response', sortKey: 'response', className: 'long', format: row => row.response, sortable: true },
                    { name: 'Respond to user', sortKey: 'respond_to_user', className: 'bool', format: row => row.respond_to_user ? '✅' : '❌', sortable: true },
                    { name: 'Access level', sortKey: 'access_level', className: 'short', format: row => <AccessLevel permission={row.access_level} />, sortable: true },
                    { name: 'Enabled', sortKey: 'is_enabled', className: 'bool', format: row => row.is_enabled ? '✅' : '❌', sortable: true },
                    { name: 'Last used', sortKey: 'last_used', className: 'date', format: row => moment(row.last_used).format("YYYY-MM-DD HH:mm:ss"), sortable: true },
                    { name: 'Times used', sortKey: 'times_used', className: 'short', format: row => row.times_used, sortable: true },
                    { name: 'Actions', sortKey: 'actions', className: 'actions', format: row => (
                        <>
                            <Popup trigger={<button className="btn-edit">📝</button>} component={EditCommand} title="Edit command" args={{ command: row }} />
                            <ConfirmDelete
                                title="Delete command"
                                target={row.name}
                                action={() => postData('deleteCommand', 'command', { command_id: row.id }).then(
                                    () => {
                                        const cb = commandList => dispatch(setCommands({ commandList: commandList, channel: selectedChannel }));
                                        fetchData('commands', 'commands', cb, { username: selectedChannel.name });
                                    }
                                )}
                                />
                        </>
                    ) }
                ]}
                data={existingCommands}
                tableAction={<Popup trigger={<button className="btn-add" href="#">Add command</button>} component={AddCommand} title="Add command" args={{ channel: selectedChannel }} />}
            />
        </section>
    );
};

export default Commands;

import React from 'react';

import { getTitle } from '../util';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ channel, path }) => {
    if (!channel) {
        return;
    }
    const title = getTitle(path);
    return (
        <nav id="breadcrumbs">
            <h1>{title} </h1>
            <ul>
                <li><Link to={`/${channel.name}/`}>{channel.display_name} Home</Link></li>
                <li><Link to={path}>{title}</Link></li>
            </ul>
        </nav>
    );
};

export default Breadcrumbs;

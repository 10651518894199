const Home = location => (
    <section id="dashboard">
            <div className="section-header">
                <h2>Dashboard</h2>
            </div>
            <hr className="divider" />
            <div className="content">
                <em>Imagine there's some stats here.</em>
            </div>
    </section>
);

export default Home;

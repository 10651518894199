import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const chatLogSlice = createSlice({
    name: 'chatLog',
    initialState: {
        channels: {},
    },
    reducers: {
        setChatLog: (state, action) => (
            {
                ...state,
                channels: {
                    ...state.channels,
                    [action.payload.channel.name]: {
                        ...state.channels[action.payload.channel.name],
                        messages: action.payload.chatLog,
                        lastUpdated: moment().format('X')
                    }
                }
            }
        )
    }
});

export const { setChatLog } = chatLogSlice.actions;
export default chatLogSlice.reducer;

import React from 'react';

const TableBody = ({ columns, rows }) => {
    return (
        <tbody>
            {rows.map(row => (
                <tr key={row.id}>
                    {columns.map(column => (
                        <td key={column.name} className={column.className}>{column.format(row)}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    )
};

export default TableBody;

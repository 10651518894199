import Breadcrumbs from "./Breadcrumbs";
import ChannelSwitcher from "./ChannelSwitcher";

const Topbar = ({ channel, path }) => (
    <>
        <header>
            <ChannelSwitcher path={path} />
        </header>
        <Breadcrumbs channel={channel} path={path} />
    </>
);

export default Topbar;

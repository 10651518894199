import React from 'react';
import Popup from 'reactjs-popup';
import PopupBase from './PopupBase';

const Alert = ({ title, info, callback }) => (
    <Popup trigger={null} modal nested defaultOpen>
        {
            close => (
                <PopupBase title={title} close={close}>
                    <div className="confirm-delete popup-body">
                        <p>{info.toString()}</p>
                        <div className="buttons">
                            <button className="btn-cancel" onClick={() => {
                                if (callback) {
                                    callback();
                                }
                                close();
                            }
                            }>OK</button>
                        </div>
                    </div>
                </PopupBase>
            )
        }
    </Popup>
);

export default Alert;

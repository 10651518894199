import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: {},
    },
    reducers: {
        setUser: (state, action) => (
            {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.name.toLowerCase()]: action.payload
                }
            }
        )
    }
});

export const { setUser } = usersSlice.actions;
export default usersSlice.reducer;

import React, { useEffect } from 'react';
import { fetchData } from "../api";
import moment from 'moment';

import UserInfo from '../components/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setChatLog } from '../reducers/chatLog';
import Table from '../components/Table';
import { formatTime } from '../util';


const Chat = () => {
    const selectedChannel = useSelector(state => state.selectedChannel.selectedChannel);
    const chatLog = useSelector(state => selectedChannel ? state.chatLog.channels[selectedChannel.name] || {} : {});
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedChannel && (!chatLog.messages || (!chatLog.lastUpdated || moment().format('X') - chatLog.lastUpdated > 10))) {
            const cb = chat => dispatch(setChatLog({ chatLog: chat, channel: selectedChannel }));
            fetchData('chat', 'chat', cb, { username: selectedChannel.name });
        }
    }, [selectedChannel, chatLog, dispatch]);

    if (!selectedChannel) {
        return null;
    }

    return (
        <section id="chat">
            <Table columns={[
                { name: 'Timestamp', className: 'date', format: row => formatTime(row.timestamp), sortKey: 'timestamp', sortable: true },
                { name: 'Chatter', className: 'short', format: row => <UserInfo userName={row.display_name} />, sortKey: 'display_name', sortable: true },
                { name: 'Message', className: 'long', format: row => row.content, sortKey: 'content', sortable: false }
            ]} data={chatLog.messages || []} title="Chat log" defaultSortColumn="timestamp" defaultSortDirection="desc" />
        </section>
    );
};

export default Chat;

import React, { useState } from 'react';
import { postData } from '../api';
import { useDispatch } from 'react-redux';
import { updateCommand } from '../reducers/commands';
import Alert from './Alert';
import AccessLevel from './AccessLevel';

const defaultErrorState = { error: false, message: '' };

const AddCommand = ({ channel, close }) => {
    const dispatch = useDispatch();

    const defaultState = {
        is_enabled: true,
        name: '',
        access_level: 'everyone',
        respond_to_user: true,
        response: '',
        channel_id: channel.id
    };

    const [item, setItem] = useState(defaultState);
    const [error, setError] = useState(defaultErrorState);

    const updateItem = property => setItem(previousState => ({ ...previousState, ...property }));

    const dispatchAndSave = () => {
        postData('addCommand', 'command', item).then(
            data => {
                if (!data) {
                    return;
                }
                dispatch(updateCommand({ channel, command: data }));
            }
        ).then(
            () => close()
        ).catch(
            error => {
                setError({ error: true, message: error.message });
            }
        )
    };

    return (
        <>
            {error.error && <Alert info={error.message} title="An error occurred!" callback={() => setError(defaultErrorState)}/>}
            <div className="edit-command popup-body">
                <div className="input-group">
                    <label htmlFor="is_enabled">Enabled</label>
                    <input type="checkbox" id="is_enabled" defaultChecked={item.is_enabled} onChange={e => updateItem({ is_enabled: e.target.checked })} />
                </div>

                <div className="input-group">
                    <label htmlFor="item_name">Command</label>
                    <input type="text" id="item_name" value={item.name} onChange={
                        e => {
                            let commandName = e.target.value;
                            if (!commandName.startsWith('!')) {
                                commandName = '!' + commandName;
                            }
                            updateItem({ name: commandName });
                        }
                    } />
                </div>

                <div className="input-group">
                    <label htmlFor="access_level">Access level {item.access_level}</label>
                    <select id="access_level" value={item.access_level || 'everyone'} onChange={e => updateItem({ access_level: e.target.value })}>
                        <option value="everyone"><AccessLevel permission="everyone" /> Everyone</option>
                        <option value="subscriber"><AccessLevel permission="subscriber" /> Subscribers</option>
                        <option value="vip"><AccessLevel permission="vip" /> VIP</option>
                        <option value="mod"><AccessLevel permission="mod" /> Moderators</option>
                        <option value="broadcaster"><AccessLevel permission="broadcaster" /> Broadcaster</option>
                    </select>
                </div>

                <div className="input-group">
                    <label htmlFor="respond_to_user">Respond to user</label>
                    <input type="checkbox" id="respond_to_user" defaultChecked={item.respond_to_user} onChange={e => updateItem({ respond_to_user: e.target.checked })} />
                </div>

                <div className="input-group">
                    <label htmlFor="is_remote">Call remote</label>
                    <input type="checkbox" id="is_remote" defaultChecked={item.is_remote} onChange={e => updateItem({ is_remote: e.target.checked })} />
                </div>

                <div className="input-group">
                    <label htmlFor="response">{item.is_remote ? 'Remote URL' : 'Response'}</label>
                    <textarea id="response" value={item.response} onChange={e => updateItem({ response: e.target.value })} />
                </div>
            </div>
            <hr className="popup-footer" />
            <div className="buttons">
                <button className="btn-submit" onClick={() => dispatchAndSave()}>Save</button>
                <button className="btn-cancel" onClick={() => { close(); }}>Cancel</button>
            </div>
        </>
    )
};

export default AddCommand;

import React, { useState } from 'react'

import TableHead from './TableHead';
import TableBody from './TableBody';

const handleSorting = (data, accessor, direction) => {
    if (direction === 'desc') {
        return [...data].sort((a, b) => {
            if (accessor(a) < accessor(b)) {
                return 1;
            }
            if (accessor(a) > accessor(b)) {
                return -1;
            }
            return 0;
        })
    }
    return [...data].sort((a, b) => {
            if (accessor(a) < accessor(b)) {
                return -1;
            }
            if (accessor(a) > accessor(b)) {
                return 1;
            }
            return 0;
        }
    )
};

const sortTable = (data, sortedColumn, direction) => {
    return handleSorting(
        data,
        row => isNaN(row[sortedColumn]) ? (row[sortedColumn] + '').toLowerCase() : row[sortedColumn],
        direction
    );
};

const DataTable = ({ columns, data, defaultSortColumn, defaultSortDirection }) => {
    const [sortColumn, setSortColumn] = useState({
        column: defaultSortColumn,
        direction: defaultSortDirection
    });
    const tableData = sortTable(data, sortColumn.column, sortColumn.direction);

    return (
        <table className="data-table">
            <TableHead columns={columns} sortColumn={sortColumn} setSortColumn={setSortColumn} />
            <TableBody columns={columns} rows={tableData} />
        </table>
    );
}

const TableHeader = ({ title, tableAction }) => {
    return (
        <>
            <div className="section-header">
                <h2>{ title }</h2>
                { tableAction }
            </div>
            <hr className="divider" />
        </>
    )
};

const TableContent = ({ columns, data, isLoading, defaultSortColumn, defaultSortDirection }) => {
    if (isLoading) {
        return (
            <em>Loading...</em>
        )
    }

    if (data.length === 0) {
        return (
            <em>No data.</em>
         );
    }

    return (
        <DataTable columns={columns} data={data} defaultSortColumn={defaultSortColumn} defaultSortDirection={defaultSortDirection} />
    );
};

const Table = ({ columns, data, title, isLoading, tableAction, defaultSortColumn, defaultSortDirection }) => {
    return (
        <>
            <TableHeader title={title} tableAction={tableAction} />
            <div className="content">
                <TableContent columns={columns} data={data} isLoading={isLoading} defaultSortColumn={defaultSortColumn || 'id'} defaultSortDirection={defaultSortDirection || 'asc'} />
            </div>
        </>
    )
};

export default Table;

import React from 'react';

const iconMap = {
    'broadcaster': '🎥',
    'mod': '⚔️',
    'vip': '👑',
    'subscriber': '⭐',
    'everyone': '👥'
};

const AccessLevel = ({ permission }) => (
    <>
        {iconMap[permission]}
    </>
);

export default AccessLevel;

import React from 'react';
import { Popup as ReactPopup } from 'reactjs-popup';
import PopupBase from './PopupBase';

const Popup = ({ component, trigger, title, args, extraProps}) => {
    const Component = component;
    return (
        <ReactPopup trigger={trigger} modal nested {...extraProps}>
        {close => (
            <PopupBase title={title} close={close}>
                <Component  {...args} close={close} />
            </PopupBase>
        )}
        </ReactPopup>
    );
};

export default Popup;

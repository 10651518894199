import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const commandsSlice = createSlice({
    name: 'commands',
    initialState: {
        channels: {},
    },
    reducers: {
        setCommands: (state, action) => (
            {
                ...state,
                channels: {
                    ...state.channels,
                    [action.payload.channel.name]: {
                        ...state.channels[action.payload.channel.name],
                        commandList: action.payload.commandList,
                        lastUpdated: moment().format('X')
                    }
                }
            }
        ),
        updateCommand: (state, action) => (
            {
                ...state,
                channels: {
                    ...state.channels,
                    [action.payload.channel.name]: {
                        ...state.channels[action.payload.channel.name],
                        commandList: {
                            ...state.channels[action.payload.channel.name].commandList,
                            [action.payload.command.id]: action.payload.command
                        }
                    }
                }
            }
        )
    }
});

export const { setCommands, updateCommand } = commandsSlice.actions;
export default commandsSlice.reducer;

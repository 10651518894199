import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PATH_MAP } from '../constants';
import { getIcon, getTitle } from '../util';
import { useSelector } from 'react-redux';

const Menu = ({ channel, path }) => {
    if (!channel) {
        return;
    }

    const isSelected = section => {
        return section === path;
    };

    return (
        <ul>
            {Object.keys(PATH_MAP).map((section, index) => (
                <li key={index} className={classNames({ selected: isSelected(section) })}>
                    <Link to={`/${channel.name}/${section}`}>
                        <span className="icon">{getIcon(section)}</span> {getTitle(section)}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const Sidebar = ({ path }) => {
    const selectedChannel = useSelector(state => state.selectedChannel.selectedChannel);

    return (
        <nav role="navigation">
            <a className="head" href="/">Boterath <span className="icon">🤖</span></a>
            <Menu channel={selectedChannel} path={path} />
        </nav>
    );
}


export default Sidebar;

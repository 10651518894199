import { createSlice } from '@reduxjs/toolkit';

const availableChannelsSlice = createSlice({
    name: 'availableChannels',
    initialState: {
        availableChannels: [],
        queried: false,
    },
    reducers: {
        setAvailableChannels: (state, action) => {
            return {
                ...state,
                queried: true,
                availableChannels: action.payload
            }
        }
    }
});

export const { setAvailableChannels } = availableChannelsSlice.actions;
export default availableChannelsSlice.reducer;

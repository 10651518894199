import { configureStore } from '@reduxjs/toolkit';
import selectedChannelReducer from './reducers/selectedChannel';
import availableChannelsReducer from './reducers/availableChannels';
import chattersReducer from './reducers/chatters';
import chatLogReducer from './reducers/chatLog';
import commandsReducer from './reducers/commands';
import usersReducer from './reducers/users';

export const store = configureStore({
    reducer: {
        selectedChannel: selectedChannelReducer,
        availableChannels: availableChannelsReducer,
        chatters: chattersReducer,
        chatLog: chatLogReducer,
        commands: commandsReducer,
        users: usersReducer,
    }
});

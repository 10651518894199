import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Chatters from './pages/Chatters';
import Chat from './pages/Chat';
import Commands from './pages/Commands';
import Login from './pages/Login';

import { extractPath, extractUsername, getBrowserTitle } from './util';
import { fetchData } from './api';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedChannel } from './reducers/selectedChannel';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { selectedChannel } = useSelector(state => state.selectedChannel);
  const username = extractUsername(location);
  const path = extractPath(location);

  // Prevent multiple fetches of the same resource
  const [fetchStatus, setFetchStatus] = useState(false);

  useEffect(() => {
    if (!fetchStatus && username && !selectedChannel) {
      setFetchStatus(true);
      const cb = channel => {
        dispatch(setSelectedChannel(channel));
        setFetchStatus(false);
      };
      fetchData('channel', 'channel', cb, { username });
    }

    document.title = getBrowserTitle(path);
  }, [username, path, selectedChannel, dispatch, fetchStatus]);

  return (
    <div>
      <Sidebar channel={selectedChannel} path={path} />
      <Topbar channel={selectedChannel} path={path} />
      <main role="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/:username" element={<Dashboard channel={selectedChannel} />} />
          <Route path="/:username/chatters" element={<Chatters channel={selectedChannel} />} />
          <Route path="/:username/chat" element={<Chat channel={selectedChannel} />} />
          <Route path="/:username/commands" element={<Commands channel={selectedChannel} />} />
        </Routes>
      </main>
      <footer>

      </footer>
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';

const selectedChannelSlice = createSlice({
    name: 'selectedChannel',
    initialState: {
        selectedChannel: null,
    },
    reducers: {
        setSelectedChannel: (state, action) => ({
            ...state,
            selectedChannel: action.payload
        })
    }
});

export const { setSelectedChannel } = selectedChannelSlice.actions;
export default selectedChannelSlice.reducer;
